html {
  height: 100%;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
